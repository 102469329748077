


























import { defineComponent, ref, PropType, onMounted } from '@vue/composition-api'
import useRating from '@/components/common/Rating/composable'
import MarkdownComponent from '@/components/common/MarkdownComponent.vue'
import { StarRating } from '@/components/common/Rating/StarRating'
import { CommonRatingAggregate, RatedCategory } from '@/interfaces'
import { Tooltip } from '@/components/common/Tooltip'

export default defineComponent({
  name: 'RatingCategoriesList',
  components: {
    StarRating,
    MarkdownComponent,
    Tooltip,
  },
  props: {
    ratings: {
      type: Array as PropType<RatedCategory[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    showText: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    isPartner: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const ratingsAggregate = ref<CommonRatingAggregate | {} | null>(null)
    const { criteriaName, formattedRatingValues } = useRating()
    const onChange = (newRating: CommonRatingAggregate) => {
      ratingsAggregate.value = Object.assign(ratingsAggregate.value, newRating)
      emit('ratingSet', ratingsAggregate.value)
    }

    onMounted(() => {
      ratingsAggregate.value = formattedRatingValues(props.ratings)
    })

    return {
      ratingsAggregate,
      criteriaName,
      onChange,
    }
  },
})
