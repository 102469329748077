
















import { defineComponent, PropType, computed } from '@vue/composition-api'
import { CommonCategories } from '@/enums'

export default defineComponent({
  name: 'StarRating',
  props: {
    value: {
      type: Number as PropType<number>,
      default: () => 0,
    },
    showText: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    isReadonly: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    name: {
      type: String as PropType<keyof CommonCategories>,
      default: () => '',
    },
    isPartner: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const rating = computed({
      get: () => props.value,
      set: (val: number) => {
        emit('change', { [props.name]: val })
      },
    })

    const color = computed(() => (props.isPartner ? '#00A984' : '#F6D838'))

    return { rating, color }
  },
})
