import round from 'lodash/round'
import { CommonCategories } from '@/enums'
import { RatedCategory } from '@/interfaces'
import { computed, SetupContext } from '@vue/composition-api'

export default function useRating(context?: SetupContext) {
  const isLoading = computed(
    () => context?.root.$store.getters['rating/isLoading']
  )

  const calculateArithmeticalMean = (ratings: RatedCategory[]) =>
    round(
      ratings.reduce((i: number, j: RatedCategory) => i + j.rating, 0) /
        ratings.length,
      1
    )

  const criteriaName = (name: string): string =>
    name?.includes(':') ? name.split(':')[1] : name

  const formattedRatingValues = (ratings?: RatedCategory[]) =>
    ratings?.length
      ? ratings.reduce(
          (prev, { category, rating }) => ({
            ...prev,
            [criteriaName(category)]: round(rating, 1),
          }),
          {}
        )
      : Object.values(CommonCategories).reduce(
          (prev, curr) => ({ ...prev, [curr]: 0 }),
          {}
        )

  const getInitialRating = (): RatedCategory[] =>
    Object.values(CommonCategories).map((category) => ({
      category,
      rating: 0,
    }))

  const ratingAggregate = (ratings: RatedCategory) =>
    Object.keys(ratings).reduce(
      (acc: RatedCategory[], category: any): RatedCategory[] => [
        {
          category,
          rating: (ratings as RatedCategory)[category],
        },
        ...acc,
      ],
      []
    )

  return {
    criteriaName,
    formattedRatingValues,
    calculateArithmeticalMean,
    getInitialRating,
    ratingAggregate,
    isLoading,
  }
}
